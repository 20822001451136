<template>
  <div id="contract">
    <mainMenu></mainMenu>
    <div class="wrapper">
      <div class="document">
        <p class="document_sub-heading">
          Договор
          <br />
          о предоставлении услуг
          <br />
          полного доступа к системе «Dentabula»
          <br />
          (Редакция № 1 от «11» мая 2018)
        </p>
        <table class="document_sub-table">
          <tr>
            <td><b>Место:</b></td>
            <td class="text-right"><b>Дата:</b></td>
          </tr>
          <tr>
            <td class="text-justify pr-100">
              местом заключения настоящего Договора является место фактического проживания Исполнителя, указанное на
              официальном сайте системы «Dentabula».
            </td>
            <td class="text-justify pl-100">
              датой и временем заключения настоящего Договора является момент оплаты физическим лицом услуг полного
              доступа к системе «Dentabula».
            </td>
          </tr>
        </table>
        <h3 class="document_sub-title">Определение терминов.</h3>
        <p class="document_sub-p">
          <b>Система «Dentabula»</b> - информационный электронный интернет-ресурс, размещенный на официальном сайте
          системы «Dentabula», содержащий в себе файловую базу данных (библиотеку) изображений и 3D моделей
          стоматологических составляющих (в том числе, но не исключительно: ортодонтических, ортопедических,
          хирургических, терапевтических, анатомических материалов), и одновременно представляет собой программу по ее
          использованию для загрузки, обработки, просмотра и вывода соответствующих специализированных компьютерных
          файлов.
        </p>
        <p class="document_sub-p">
          <b>Официальный сайт системы «Dentabula»</b> - сайт (ресурс) в сети Интернет, размещенный на внешних серверах
          по адресу: <a href="https://www.dentabula.com">https://www.dentabula.com</a>.
        </p>
        <p class="document_sub-p">
          <b>Программа</b> - программа для электронных вычислительных машин (ЭВМ) «Dentabula» всех редакций (как в
          целом, так и ее отдельные компоненты), размещенная на внешних серверах, которая представлена в форме
          совокупности базы данных и команд включенных в ее состав, и предназначена для загрузки, обработки, просмотра и
          вывода соответствующих специализированных компьютерных файлов. Программа работает в режиме клиент-сервер.
        </p>
        <p class="document_sub-p">
          <b>Услуга</b> - предоставление физическому лицу полного доступа к системе «Dentabula» в рамках определенного
          оплаченного периода времени.
        </p>
        <p class="document_sub-p">
          <b>Полный доступ к системе «Dentabula»</b> - доступ физического лица ко всем элементам системы «Dentabula», с
          возможностью загрузки, обработки, просмотра и вывода соответствующих специализированных компьютерных файлов, в
          пределах предоставленного такой системой функционала.
        </p>
        <p class="document_sub-p">
          <b>Уникальное имя (логин) учетной записи</b> - символьный код, который используется для работы в системе
          «Dentabula». Создается физическим лицом один раз при регистрации и подключении к системе, является уникальным
          для каждого физического лица и сохраняется за ним на все время.
        </p>
        <p class="document_sub-p">
          <b>Учетная запись</b> - запись в системе «Dentabula», хранящая данные, которые позволяют идентифицировать
          конкретное физическое лицо, как зарегистрированного пользователя такой системы.
        </p>
        <p class="document_sub-p">
          <b>Пользователь</b> - физическое лицо, зарегистрированное на официальном сайте системы «Dentabula» и
          использует ее на условиях полного доступа.
        </p>
        <p class="document_sub-p">
          <b>Исполнитель</b> с одной стороны, и любое физическое лицо (гражданин Украины, иностранец, лицо без
          гражданства и др.), которое осуществило акцепт (принятие) условий настоящего Договора (далее -
          <b>Заказчик</b>), с другой стороны, которые вместе дальше по тексту именуемые <b>«Стороны»</b>, а по
          отдельности - <b>«Сторона»</b>, действуя добровольно и по собственному волеизъявлению, пришли к взаимному
          согласию заключить настоящий Договор о нижеследующем:
        </p>

        <h3 class="document_sub-title">1. Предмет Договора.</h3>
        <p class="document_sub-list">
          1.1. На основании данного договора, в порядке и на определенных им условиях, Исполнитель принимает на себя
          обязательства предоставлять Заказчику услуги полного доступа к системе «Dentabula» (далее по тексту - Система)
          в пределах оплаченного периода времени (далее - Услуги), а Заказчик в свою очередь обязуется такие услуги
          оплачивать.
        </p>
        <p class="document_sub-list">
          1.2. Исполнитель оказывает Заказчику услуги путем предоставления полного доступа ко всем элементам Системы, с
          возможностью загрузки, обработки, просмотра и вывода соответствующих специализированных компьютерных файлов, в
          пределах предоставленного такой Системой функционала, исключительно в течение определенного ограниченного
          периода времени.
        </p>
        <p class="document_sub-list">
          1.3. Условия настоящего Договора могут быть изменены Исполнителем в одностороннем порядке, без согласования с
          Заказчиком, и без какого-либо специального уведомления. Новая редакция условий Договора вступает в силу с
          момента ее размещения на официальном сайте Системы, если иное не предусмотрено новой редакцией Договора.
          Текущая и действующая редакция Договора всегда находится на официальном сайте Системы по адресу:
          https://www.dentabula.com, с указанием номера и даты ее редакции.
        </p>
        <p class="document_sub-list">
          1.4. Форма настоящего Договора отвечает требованиям частного права, указанного в п. 1.5. Договора.
        </p>
        <p class="document_sub-list">
          1.5. К содержанию настоящего Договора применяется частное право национального законодательства Украины.
        </p>

        <h3 class="document_sub-title">2. Порядок и условия предоставления Услуг.</h3>
        <p class="document_sub-list">
          2.1. Услуги потребляются Заказчиком в процессе совершения им каких-либо действий по использованию Системы.
          Исполнитель обязуется оказывать Услуги исключительно с первого дня и по последний день соответствующего
          оплаченного Заказчиком периода времени. Предоставление Исполнителем услуг начинается сразу после оплаты
          Заказчиком их стоимости, согласно условиям настоящего Договора, и прекращается сразу после окончания
          оплаченного периода времени. Период времени, в течение которого Исполнитель обязуется оказывать Заказчику
          услуги, а также стоимость таких услуг определена на официальном сайте Системы, по адресу:
          https://www.dentabula.com. Заключая настоящий Договор, Заказчик подтверждает, что он ознакомлен с условиями,
          периодом и стоимостью предоставления услуг Исполнителем.
        </p>
        <p class="document_sub-list">
          2.2. Услуги предоставляются Исполнителем лично. При необходимости, по усмотрению Исполнителя и по его желанию,
          без предварительного согласия Заказчика и не вступая в противоречие с условиями настоящего Договора,
          Исполнитель вправе в любой момент переложить выполнение Договора на другое лицо, оставаясь при этом
          ответственным в полном объеме перед Заказчиком за нарушение его условий.
        </p>
        <p class="document_sub-list">
          2.3. Услуги, предоставляемые по настоящему Договору, должны отвечать следующим свойствам:
          <br />
          - возможность непрерывного и бесперебойного доступа Заказчика к Системе в любое время любого дня, только в
          пределах и в течение оплаченного им периода, при условии наличия у Заказчика непрерывного и бесперебойного
          доступа к сети Интернет;
          <br />
          - возможность загрузки, обработки, просмотра и вывода Заказчиком соответствующих специализированных
          компьютерных файлов, которые содержат в себе изображение и 3D модели стоматологических составляющих (в том
          числе, но не исключительно: ортодонтических, ортопедических, хирургических, терапевтических, анатомических
          материалов).
        </p>
        <p class="document_sub-list">
          2.4. Для возможности выполнения обязательств по настоящему Договору, Заказчик должен самостоятельно
          зарегистрироваться в Системе на ее официальном сайте.
        </p>
        <p class="document_sub-list">
          2.5. При регистрации Заказчик самостоятельно выбирает себе уникальное имя (логин) учетной записи и пароль для
          доступа к нему. Исполнитель имеет право запретить использование определенных логинов, а также устанавливать
          требования к логину и паролю (длина, допустимые символы и др.).
        </p>
        <p class="document_sub-list">
          2.6. Заказчик самостоятельно несет ответственность за безопасность (устойчивость к угадыванию) выбранного им
          пароля, а также самостоятельно обеспечивает конфиденциальность своего пароля. Заказчик самостоятельно несет
          ответственность за все действия / бездействие (а также их последствия) в рамках или с использованием Системы
          под его учетной записью, включая случаи добровольной передачи или несоблюдение конфиденциальности данных для
          доступа к учетной записи Заказчика третьим лицам на любых условиях (в том числе по договорам или соглашениям).
          При этом все действия в рамках или с использованием Системы под учетной записью Заказчика считаются
          осуществленными самим Заказчиком, за исключением получения Исполнителем от Заказчика направленного
          электронного сообщения о несанкционированном использовании Системы под учетной записью Заказчика и / или о
          любом нарушении (подозрении о нарушении) конфиденциальности своего пароля.
        </p>
        <p class="document_sub-list">
          2.7. Заказчик обязан немедленно уведомить Исполнителя о любой случай несанкционированного (не разрешенного
          Заказчиком) доступа к Системе с помощью учетной записи Заказчика и / или о любом нарушении (подозрении о
          нарушении) конфиденциальности своего пароля. В целях безопасности, Заказчик обязан самостоятельно осуществлять
          безопасное завершение работы под своей учетной записью (кнопка «Выход») по окончании каждой сессии работы с
          Системой. Исполнитель не несет ответственности за возможную потерю или порчу данных, а также другие
          последствия любого характера, которые могут произойти из-за нарушения Заказчиком положений данного пункта
          Договора.
        </p>
        <p class="document_sub-list">
          2.8. Исполнитель вправе заблокировать и / или удалить учетную запись Заказчика, включая весь загруженный в
          Систему последним контент без объяснения причин (в том числе, но не исключительно, в случае нарушения
          Заказчиком условий Договора), в случае неиспользования Системы в течение 50 (пятидесяти) календарных дней
          подряд. Под неиспользованием Системы в настоящем Договоре понимается отсутствие данных в архивной системе
          Исполнителя об авторизации Заказчика в Системе.
        </p>
        <p class="document_sub-list">
          2.9. Заказчику не разрешается использовать Систему любым способом, если такое использование противоречит или
          приводит к нарушению национального законодательства (права) Украины и / или международных договоров, в которых
          принимает участие Украина, и предписаний национального коллизионного законодательства Украины.
        </p>
        <p class="document_sub-list">
          2.10. После оплаты Услуг, Заказчику предоставляется временный полный доступ к Системе, при этом последний не
          вправе совершать действия, которые могут вызвать: а) нарушение функционирования оборудования и сети
          Исполнителя; б) нарушение работы Системы или ограничение возможностей других пользователей в использовании
          Системы; в) несанкционированный доступ к Системе, а также в информационно-вычислительных и сетевых ресурсов
          Исполнителя; г) причинение или угрозу причинения вреда третьим лицам.
        </p>
        <p class="document_sub-list">
          2.11. Заказчику запрещается модифицировать Систему, ее дизайн и внешний вид.
        </p>
        <p class="document_sub-list">
          2.12. Заказчик самостоятельно обеспечивает подключение своего рабочего места к сети Интернет, а также
          самостоятельно обеспечивает комплектацию своего рабочего места в соответствии с требованиями, размещенными на
          официальном сайте Системы.
        </p>
        <p class="document_sub-list">
          2.13. Заказчик гарантирует, что он имеет все необходимые права на все данные, компьютерные программы или
          сервисы, используемые им в связи с использованием Системы, и такие действия не нарушают каких-либо прав
          третьих лиц.
        </p>
        <p class="document_sub-list">
          2.14. Заказчик не имеет права воспроизводить Систему, а также копировать, продавать и перепродавать ее
          (включая контент, доступный Заказчику в рамках Системы), или доступ к ней, кроме тех случаев, когда заказчик
          получил такое разрешение от Исполнителя.
        </p>

        <h3 class="document_sub-title">3. Стоимость услуг и порядок оплаты.</h3>
        <p class="document_sub-list">
          3.1. Стоимость услуг по настоящему Договору указана на официальном сайте Системы, по адресу:
          https://www.dentabula.com. Заключая настоящий Договор, Заказчик подтверждает, что он ознакомлен со стоимостью
          предоставления услуг Исполнителем.
        </p>
        <p class="document_sub-list">
          3.2. Исполнитель вправе в любое время в одностороннем порядке и без предварительного согласования с Заказчиком
          изменять стоимость Услуг. Измененная Исполнителем стоимость услуг вступает в силу на 2-й (второй) календарный
          день с момента его опубликования на официальном сайте Системы, если иной срок вступления в силу не указан
          Исполнителем на официальном сайте Системы. В случае изменения стоимости Услуг уже осуществленная ранее
          Заказчиком оплата до момента вступления в силу новой стоимости - не пересчитывается.
        </p>
        <p class="document_sub-list">
          3.3. Оплата услуг осуществляется Заказчиком в безналичной форме, путем перечисления соответствующей суммы
          денежных средств на расчетный счет Исполнителя, посредством использования международных банковских платежных
          карт через соответствующую платежную систему (интернет-эквайринг), выбранной в одностороннем порядке и по
          усмотрению Исполнителя.
        </p>
        <p class="document_sub-list">
          3.4. Заключая настоящий Договор, Заказчик подтверждает, что он осведомлен относительно следующего: Исполнитель
          не требует от Заказчика предоставления каких-либо сведений о его платежных инструментах, банковских счетах,
          платежных карточках, а также любых других сведений, необходимых для оплаты стоимости Услуг по настоящему
          Договору. Исполнитель ни в коем случае не несет ответственности за сохранность указанных выше и подобных
          сведений, позволяющих обеспечить проведение Заказчиком оплаты Услуг соответствующим оператором платежных
          систем, даже если такие сведения были получены Исполнителем случайно или из-за умышленных или ошибочных
          действий Заказчика.
        </p>
        <p class="document_sub-list">
          3.5. Информация о включении в расчет стоимости услуг по настоящему Договору налогов - отмечается на
          официальном сайте Системы.
        </p>
        <p class="document_sub-list">
          3.6. Сразу же после оплаты Услуг, Заказчик получает подтверждение об этом в форме электронной квитанции,
          которая по желанию последнего может быть отправлена на его адрес электронной почты или загружена на
          соответствующее устройство для хранения данных.
        </p>
        <p class="document_sub-list">
          3.7. Заключая настоящий Договор Стороны определили следующее:
        </p>
        <p class="document_sub-list">
          3.7.1. Стоимость Услуг по настоящему Договору определяется, в том числе и в виде денежного эквивалента в
          иностранной валюте (выбранной в одностороннем порядке Исполнителем и по его усмотрению) на официальном сайте
          «Dentabula» в сети Интернет по адресу: https://www.dentabula.com. Размер стоимости услуг, выраженный в
          национальной валюте Украины, каждый раз зависит от ежедневного текущего валютного курса продажи
          соответствующей иностранной валюты за гривны, по данным Национального банка Украины. При оплате Заказчиком
          стоимости услуг сумма такого платежа в национальной валюте Украины автоматически определяется соответствующим
          текущим валютным курсом продажи соответствующей иностранной валюты за гривны по состоянию на соответствующий
          день осуществления Заказчиком такого платежа. Осуществляя оплату стоимости Услуг Заказчик соглашается с тем,
          что конечная сумма платежа может изменяться в соответствии с валютно-обменной политикой Национального банка
          Украины.
        </p>

        <h3 class="document_sub-title">4. Права и обязанности Сторон.</h3>
        <p class="document_sub-list">
          4.1. Исполнитель обязан:
          <br />
          - своевременно, надлежащим образом и в полном объеме выполнять обязательства по настоящему Договору;
          <br />
          - предоставлять Заказчику услуги исключительно в течение оплаченного последним ограниченного периода времени;
        </p>
        <p class="document_sub-list">
          4.2. Заказчик обязан:
          <br />
          - своевременно, надлежащим образом и в полном объеме выполнять условия данного Договора
          <br />
          - оплачивать стоимость Услуг в соответствии с условиями Договора;
          <br />
          - для корректной работы Системы обеспечить себя и свое рабочее место соответствующей аппаратно-программной
          средой, требования к которой приведены на официальном сайте Системы;
          <br />
          - самостоятельно и за собственные средства и собственными силами обеспечивать конфиденциальность своего логина
          и пароля, необходимых для доступа к его учетной записи в Системе;
          <br />
          - не передавать Услуги третьим лицам.
        </p>
        <p class="document_sub-list">
          4.3. Исполнитель имеет право:
          <br />
          - изменить состав, порядок, условия и стоимость предоставления Услуг путем размещения соответствующей
          информации на официальном сайте Системы;
          <br />
          - прекратить предоставлять Услуги автоматически после окончания оплаченного Заказчиком периода;
          <br />
          - приостановить или остановить предоставление Услуг Заказчику без возврата денежных средств и требовать
          письменных объяснений от Заказчика в случаях: если Исполнитель считает, что любые действия, совершенные
          Заказчиком через Услуги, предоставляемые Заказчику по настоящему Договору, наносят или могут нанести ущерб
          Исполнителю и / или другим Заказчикам; в случае повреждения или попытки повредить средства защиты Системы, а
          также включение программного обеспечения в состав собственных разработок Заказчика; при публикации и передачи
          Заказчиком через сеть интернет любой информации, порочащей Исполнителя или совершении действий, препятствующих
          нормальной работе Исполнителя
          <br />
          - заблокировать доступ к Системе с определенного IP-адреса в случаях: превышения количества запросов с
          IP-адреса за определенный промежуток времени; направление запросов, которые не предусмотрены клиентской
          программой Системы; действий с Системой, которые могут привести к последствиям, предусмотренным разделом XVI
          Уголовного кодекса Украины «Преступления в сфере использования электронно-вычислительных машин (компьютеров),
          систем и компьютерных сетей и сетей электросвязи».
        </p>
        <p class="document_sub-list">
          4.4. Заказчик имеет право:
          <br />
          - требовать от Исполнителя предоставления Услуг в соответствии с условиями настоящего Договора;
          <br />
          - в случае возникновения замечаний и / или претензий по качеству услуг сообщать о них Исполнителю в письменном
          виде. Замечания и / или претензии принимаются в письменном виде, изложенные на украинском, английском или
          русском языках, путем их направления на следующий адрес электронной почты Исполнителя: dentabula.AP@gmail.com.
          Замечания и / или претензии рассматривается Исполнителем в течение 30 календарных дней с момента их получения.
          В случае, когда замечания и / или претензии являются надуманными и / или необоснованными, Исполнитель имеет
          право оставить их без рассмотрения и / или без ответа;
          <br />
          - отказаться от настоящего Договора, и требовать возмещения убытков, если Исполнитель своевременно не
          приступил к выполнению своих обязательств по Договору;
          <br />
          - расторгнуть договор, если станет очевидным, что Услуги не будут предоставлены Исполнителем в соответствии с
          условиями настоящего Договора;
          <br />
          - в случае выявления недостатков в Услугах, Заказчик вправе по своему выбору потребовать: безвозмездного
          устранения недостатков в оказанной Услуге в разумный срок; соответствующего уменьшения цены оказанной Услуги.
          <br />
        </p>

        <h3 class="document_sub-title">
          5. Условия использования Системы.
          <br />
          Авторские права.
        </h3>
        <p class="document_sub-list">
          5.1. Исполнитель позволяет Заказчику обрабатывать, просматривать, загружать и выводить материалы Системы
          только для личного использования, при условии сохранения Заказчиком всей информации об авторском праве и
          других сведений о праве собственности, содержащихся в исходных материалах Системы и любых их копиях.
          Запрещается изменять материалы Системы, а также распространять их в любом виде. Любое использование материалов
          Системы на других сайтах или в компьютерных сетях запрещается.
        </p>
        <p class="document_sub-list">
          5.2. Система является результатом интеллектуальной деятельности Исполнителя и объектом его авторских прав,
          которые регулируются и защищены национальным законодательством (правом) Украины с учетом международных
          договоров, в которых принимает участие Украина, и предписаний национального коллизионного законодательства
          Украины.
        </p>
        <p class="document_sub-list">
          5.3. Алгоритмы работы Системы и ее исходные коды (в том числе их части) являются коммерческой тайной
          Исполнителя. Любое их использование или использование Системы в нарушение условий настоящего Договора
          рассматривается как нарушение прав Исполнителя и является достаточным основанием для лишения Заказчика
          предоставленных по настоящему Договору прав, и его дальнейшего привлечения к соответствующей правовой
          ответственности.
        </p>
        <p class="document_sub-list">
          5.4. Ответственность за нарушение авторских прав наступает в соответствии с национальным законодательством
          (права) Украины с учетом международных договоров, в которых принимает участие Украина, и предписаний
          национального коллизионного законодательства Украины.
        </p>
        <p class="document_sub-list">
          5.5. Система и авторство на нее, идеи, методы работы, документация и другая информация, которая содержится и /
          или может быть размещена в Системе, является интеллектуальной собственностью и / или коммерческой тайной
          Исполнителя, охраняется национальным законодательством (правом) Украины с учетом международных договоров, в
          которых принимает участие Украина, и предписаний национального коллизионного законодательства Украины.
          Исполнитель сохраняет за собой все права, связанные с Системой, включая без всяких ограничений любые
          исправления ошибок, улучшения, обновления или другие операции по модификации Системы, а также все авторские
          права, связанные с интеллектуальной собственностью на Систему. Факты использования Заказчиком Системы ни в
          коем случае не дают последнему никаких прав интеллектуальной собственности на нее, и Заказчик не получает иных
          прав на Систему, кроме специально оговоренных в настоящем Договоре. Настоящий Договор только дает Заказчику
          право использования Системы исключительно в рамках настоящего Договора.
        </p>
        <p class="document_sub-list">
          5.6. Заказчик соглашается, что он не будет менять, декомпилировать, анализировать, и / или пытаться раскрыть
          исходный код Системы.
        </p>
        <p class="document_sub-list">
          5.7. Заказчик соглашается, что все авторские права, и все другие связанные с этим права собственности на
          официальном сайте Системы (в том числе, но не исключительно: файловая база данных (библиотека) изображений и
          3D моделей ортодонтических, ортопедических, хирургических, терапевтических, анатомических стоматологических
          составляющих и других связанных с этим материалов, а также Программа, с помощью которой осуществляется
          использование Системы для загрузки, обработки, просмотра и вывода соответствующих специализированных
          компьютерных файлов) принадлежат Исполнителю. Заказчик соглашается и обязуется не копировать, не переиздавать,
          не кадрировать, не загружать, не передавать, не менять, не сдавать в аренду, не продавать, не распространять,
          не лицензировать, а не перепроектировать и перепрограммировать Систему и / или отдельные ее части, или
          создавать производные от нее и основанные на ней объекты, сайты, системы, программы, базы данных и тому
          подобное.
        </p>

        <h3 class="document_sub-title">6. Отказ от гарантий.</h3>
        <p class="document_sub-list">
          6.1. Материалы Системы и Услуги по настоящему Договору предоставляются Исполнителем Заказчику «как есть» и
          «как доступно», и без каких-либо гарантий. Исполнитель не гарантирует точности и полноты материалов, программ
          и Услуг, предоставляемых Системой и Исполнителем. Исполнитель в любое время без уведомления Заказчика может
          вносить изменения в материалы и Услуги, предоставляемые Системой и Исполнителем. В случае старения материалов
          Системы Исполнитель не обязан обновлять их. Исполнитель ни при каких обстоятельствах не несет ответственности
          за любые убытки возникшие вследствие использования, невозможности использования или результатов использования
          Системы.
        </p>
        <p class="document_sub-list">
          6.2. Исполнитель не дает никаких обещаний и / или гарантий (явных и / или неявных). Заказчик принимает на себя
          весь риск и ответственность за использование Системы для получения каких-либо результатов.
        </p>
        <p class="document_sub-list">
          6.3. Исполнитель не дает никаких гарантий на то, что в Системе не будут содержаться ошибки, не будет никаких
          пауз и / или сбоев в ее работе, и что она будет совместима и / или поддерживаться любой аппаратно-программной
          и / или операционной системой.
        </p>
        <p class="document_sub-list">
          6.4. Исполнитель снимает с себя любую ответственность за потерю каких-либо данных при использовании Системы и
          во время процесса обмена ими, а также ответственность за не передачу самой полной и достоверной информации.
        </p>
        <p class="document_sub-list">
          6.5. Использование Системы осуществляется Заказчиком на его собственный риск и по его согласию. Заказчик несет
          персональную и полную ответственность за любые повреждения его устройств, оборудования и / или программ,
          потери данных или другой причиненный вред в результате использования Системы. Исполнитель не несет
          ответственности за заражение компьютерными вирусами или подобными программными кодами, которые могут быть
          загружены Заказчиком с официального сайта Системы.
        </p>

        <h3 class="document_sub-title">7. Ответственность Сторон.</h3>
        <p class="document_sub-list">
          7.1. Стороны настоящего Договора несут ответственность по предмету и условиям Договора в соответствии с
          национальным законодательством (правом) Украины с учетом международных договоров, в которых принимает участие
          Украина, и предписаний национального коллизионного законодательства Украины.
        </p>
        <p class="document_sub-list">
          7.2. Заказчик полностью ответственен за сохранность своего логина и пароля и за убытки, которые могут
          возникнуть по причине несанкционированного его использования. По факту кражи логина и пароля по вине третьих
          лиц клиент вправе направить в адрес Исполнителя заявление о смене логина и пароля, с обязательным приложением
          к заявлению соответствующей квитанции, подтверждающей оплату Услуг. Исполнитель не несет ответственность за
          действия третьих лиц, повлекших кражу, а для возмещения денежных средств, потраченных за украденное время
          Заказчик может обратиться в соответствующие следственные и правоохранительные органы по месту своего
          нахождения.
        </p>

        <h3 class="document_sub-title">8. Форс-мажорные обстоятельства.</h3>
        <p class="document_sub-list">
          8.1. Стороны настоящего Договора освобождается от любой ответственности за полное или частичное нарушение
          условий настоящего Договора, если докажут, что такое нарушение произошло вследствие действия форс-мажорных
          обстоятельств, определенных в настоящем Договоре, при условии, что их наступление было засвидетельствовано в
          определенном настоящим Договором порядке.
        </p>
        <p class="document_sub-list">
          8.2. Под форс-мажорными обстоятельствами в настоящем Договоре понимаются случай и / или непреодолимая сила,
          определенные в п.п. 8.3. и 8.4. настоящего Договора, как основание для освобождения от ответственности за
          нарушение условий Договора.
        </p>
        <p class="document_sub-list">
          8.3. Под непреодолимой силой в настоящем Договоре понимаются любые чрезвычайные события внешнего относительно
          Сторон характера, которые возникают без вины Сторон, вне их воли или вопреки воле или желанию Сторон, и
          которые нельзя при условии употребления обычных для этого мероприятий предусмотреть и нельзя при всей
          заботливости и осмотрительности отвлечь (избежать), включая (но не ограничиваясь): стихийные явления
          природного характера (землетрясения, наводнения, ураганы, разрушения в результате молнии и т.п.), бедствия
          биологического, техногенного и антропогенного происхождения (взрывы, пожары, выход из строя машин и
          оборудования, массовые эпидемии, эпизоотии, эпифитотии т.п.), обстоятельства общественной жизни (война,
          военные действия, блокады, общественные волнения, проявления терроризма, массовые забастовки и локауты,
          бойкоты и т.п.), а также издание запретительных или ограничивающих нормативных актов органов государственной
          власти или местного самоуправления, другие законные или незаконные запретительные или ограничительные меры
          названных органов, которые делают невозможным выполнение Сторонами настоящего Договора или временно
          препятствуют такому исполнению.
        </p>
        <p class="document_sub-list">
          8.4. Под случаем в настоящем Договоре понимаются любые обстоятельства, которые не считаются непреодолимой
          силой по этому Договору и которые непосредственно не обусловлены действиями Сторон и не связаны с ними
          причинной связью, которые возникают без вины Сторон, вне их воли или вопреки воле или желанию Сторон, и
          которые нельзя при условии употребления обычных для этого мероприятий предусмотреть и нельзя при всей
          заботливости и осмотрительности отвлечь (избежать).
        </p>
        <p class="document_sub-list">
          8.5. Не считаются случаем несоблюдения своих обязанностей контрагентом той Стороны, которая нарушила настоящий
          Договор, отсутствие у Стороны, нарушившей Договор, необходимых средств.
        </p>
        <p class="document_sub-list">
          8.6. Наступление непреодолимой силы должно быть засвидетельствовано компетентным органом, который определен
          действующим законодательством.
        </p>
        <p class="document_sub-list">
          8.7. Сторона, которая намерена сослаться на форс-мажорные обстоятельства, обязана безотлагательно с учетом
          возможностей технических средств мгновенной связи и характера существующих препятствий сообщить другой Стороне
          о наличии форс-мажорных обстоятельств и их влиянии на исполнение настоящего Договора.
        </p>
        <p class="document_sub-list">
          8.8. Если форс-мажорные обстоятельства и их последствия временно препятствуют выполнению настоящего Договора,
          то исполнение настоящего Договора приостанавливается на срок, в течение которого оно невозможно.
        </p>
        <p class="document_sub-list">
          8.9. Если в связи с форс-мажорными обстоятельствами и их последствиями, за которые ни одна из Сторон не
          отвечает, выполнение настоящего Договора является окончательно невозможным, то настоящий Договор считается
          прекращенным с момента возникновения невозможности выполнения настоящего Договора, однако Стороны не
          освобождаются от обязанности, определенной в п. 8.7. настоящего Договора.
        </p>
        <p class="document_sub-list">
          8.10. Если в связи с форс-мажорными обстоятельствами и их последствиями выполнения этого Договора является
          временно невозможным и такая невозможность продолжается в течение 90 календарных дней и не проявляет признаков
          прекращения, то настоящий Договор может быть расторгнут в одностороннем порядке любой Стороной путем
          направления с помощью электронного почтовой связи письменного заявления другой Стороне.
        </p>

        <h3 class="document_sub-title">
          9. Управляющее законодательство (право).
          <br />
          Договорная подсудность.
        </h3>
        <p class="document_sub-list">
          9.1. Стороны согласовали, что настоящий Договор в целом и связанные с ним правовые отношения между Сторонами
          регулируются, толкуются и выполняются в соответствии с национальным законодательством (правом) Украины. В
          случае, когда Заказчиком является гражданин Украины, проживающий за ее пределами, или иностранец, или лицо без
          гражданства, отношения между Сторонами регулируются в соответствии с национальным законодательством (правом)
          Украины с учетом международных договоров, в которых принимает участие Украина, и предписаний национального
          коллизионного законодательства Украины.
        </p>
        <p class="document_sub-list">
          9.2. Все споры, разногласия или требования, возникающие из настоящего Договора или в связи с ним, в частности,
          его толкования, исполнения, нарушения, прекращения или недействительности, подлежат разрешению в соответствии
          с национальным законодательством (правом) Украины с учетом международных договоров, в которых принимает
          участие Украина.
        </p>
        <p class="document_sub-list">
          9.3. Все споры и / или разногласия, которые могут возникнуть на основании настоящего Договора или в связи с
          ним, должны быть переданы на разрешение соответствующему местному суду Украины общей юрисдикции по месту
          нахождения Исполнителя.
        </p>

        <h3 class="document_sub-title">10. Срок действия Договора и порядок его расторжения.</h3>
        <p class="document_sub-list">
          10.1. Договор вступает в силу с момента акцепта оферты Заказчиком, которая осуществляется путем оплаты Услуг в
          определенный его условиями способ и порядок, и действует до момента его расторжения по соглашению сторон либо
          по инициативе одной Стороны в порядке, определенном Договором.
        </p>
        <p class="document_sub-list">
          10.2. Договор может быть расторгнут в следующих случаях:
          <br />
          - по соглашению Сторон в любое время;
          <br />
          - по инициативе любой Стороны в случае нарушения условий Договора другой Стороной с обязательным письменным
          уведомлением другой Стороны, отправленным на электронную почту;
          <br />
          - по инициативе любой Стороны с письменным уведомлением другой Стороны за 15 календарных дней до расторжения
          Договора, отправленным на электронную почту.
        </p>
        <p class="document_sub-list">
          10.3. Прекращения или расторжения Договора не освобождает Стороны от исполнения обязанностей, возникших до или
          в момент прекращения или расторжения Договора.
        </p>

        <h3 class="document_sub-title">11. Другое.</h3>
        <p class="document_sub-list">
          11.1. Любые сообщения, письма, замечания и / или претензии по настоящему Договору могут направляться
          Заказчиком Исполнителю по адресу электронной почты с адреса, указанного Заказчиком при регистрации,
          Исполнителем Заказчику по адресу, указанному Заказчиком при регистрации с адреса, указанного в разделе 12
          «Реквизиты Исполнителя».
        </p>
        <p class="document_sub-list">
          11.2. Внесение изменений в настоящий Договор осуществляется согласно пункту 1.3. Договора.
        </p>
        <p class="document_sub-list">
          11.3. Возможная недействительность одного или более условий Договора не влечет за собой недействительности
          всего Договора или отдельных его частей, которые остаются в силе.
        </p>
        <p class="document_sub-list">
          11.4. В случаях, не урегулированных настоящим Договором, Стороны руководствуются нормами национального
          законодательства (права) Украины с учетом международных договоров, в которых принимает участие Украина, и
          предписаний национального коллизионного законодательства Украины.
        </p>
        <p class="document_sub-list">
          11.5. Каждая из Сторон подтверждает, что: она имеет все полномочия заключить настоящий Договор, не существует
          каких-либо ограничений на заключение Договора, условия данного Договора понятны и соответствуют реальной
          договоренности Сторон; заключения Договора отвечает интересам Сторон.
        </p>

        <h3 class="document_sub-title">12. Реквизиты Исполнителя.</h3>
        <p class="document_sub-p">
          Физическое лицо-предприниматель Погарский Антон Юрьевич
        </p>
        <p class="document_sub-p">
          Дата и номер записи в Едином государственном реестре юридических лиц, физических лиц-предпринимателей и
          общественных формирований: 28.03.2018 года, № 21030000000096301;
        </p>
        <p class="document_sub-p">
          адрес (место фактического проживания): 69600, Украина, Запорожская область, город Запорожье, Коммунарский
          район, ул. Реконструктивная, дом 3;
        </p>
        <p class="document_sub-p">
          регистрационный номер учетной карточки налогоплательщика: 2987814459;
        </p>
        <p class="document_sub-p">
          адрес электронной почты: dentabula.AP@gmail.com;
        </p>
        <p class="document_sub-p">
          режим работы: с понедельника по пятницу каждую неделю, с 10 час. 00 мин. до 17 час. 00 мин.;
        </p>
        <p class="document_sub-p">
          тел .: + 38 097 977 39 98.
        </p>
      </div>
      <div class="document">
        <p class="document_sub-heading">
          Договір
          <br />
          про надання послуг
          <br />
          з повного доступу до системи «Dentabula»
          <br />
          (редакція № 1 від «11» травня 2018 року)
        </p>

        <table class="document_sub-table">
          <tr>
            <td><b>Місце:</b></td>
            <td class="text-right"><b>Дата:</b></td>
          </tr>
          <tr>
            <td class="text-justify pr-100">
              місцем укладання даного Договору є місце фактичного проживання Виконавця, зазначене на офіційному сайті
              системи «Dentabula».
            </td>
            <td class="text-justify pl-100">
              датою та часом укладання даного Договору є момент оплати фізичною особою послуг з повного доступу до
              системи «Dentabula».
            </td>
          </tr>
        </table>

        <h3 class="document_sub-title">Визначення термінів.</h3>
        <p class="document_sub-p">
          <b>Система «Dentabula»</b> – інформаційний електронний інтернет-ресурс, розміщений на офіційному сайті системи
          «Dentabula», що містить в собі файлову базу даних (бібліотеку) зображень та 3D моделей стоматологічних
          складових (в тому числі, але не виключно: ортодонтичних, ортопедичних, хірургічних, терапевтичних, анатомічних
          матеріалів), та одночасно являє собою програму з її використання для завантаження, обробки, перегляду та
          виводу відповідних спеціалізованих комп’ютерних файлів.
        </p>
        <p class="document_sub-p">
          <b>Офіційний сайт системи «Dentabula»</b> – сайт (ресурс) в мережі Інтернет, розміщений на зовнішніх серверах
          за адресою: <a href="https://www.dentabula.com">https://www.dentabula.com</a>.
        </p>
        <p class="document_sub-p">
          <b>Програма</b> – програма для електронних обчислювальних машин (ЕОМ) «Dentabula» всіх редакцій (як в цілому,
          так і її окремі компоненти), розміщена на зовнішніх серверах, яка представлена у формі сукупності бази даних і
          команд включених до її складу, і призначена для завантаження, обробки, перегляду та виводу відповідних
          спеціалізованих комп’ютерних файлів. Програма працює в режимі клієнт-сервер.
        </p>
        <p class="document_sub-p">
          <b>Послуга</b> – надання фізичній особі повного доступу до системи «Dentabula» в межах певного оплаченого
          періоду часу.
        </p>
        <p class="document_sub-p">
          <b>Повний доступ до системи «Dentabula»</b> – доступ фізичної особи до всіх елементів системи «Dentabula», з
          можливістю завантаження, обробки, перегляду та виводу відповідних спеціалізованих комп’ютерних файлів, в межах
          наданого такою системою функціоналу.
        </p>
        <p class="document_sub-p">
          <b>Унікальне ім’я (логін) облікового запису</b> – символьний код, який використовується для роботи в системі
          «Dentabula». Створюється фізичною особою один раз при реєстрації та підключенні до системи, є унікальним для
          кожної фізичної особи та зберігається за нею на весь час.
        </p>
        <p class="document_sub-p">
          <b>Обліковий запис</b> – запис у системі «Dentabula», що зберігає дані, які дозволяють ідентифікувати
          конкретну фізичну особу, як зареєстрованого користувача такої системи.
        </p>
        <p class="document_sub-p">
          <b>Користувач</b> – фізична особа, яка зареєстрована на офіційному сайті системи «Dentabula» та використовує
          її на умовах повного доступу.
        </p>
        <p class="document_sub-p">
          <b>Виконавець</b> з однієї сторони, та будь-яка фізична особа (громадянин України, іноземець, особа без
          громадянства, та ін.), яка здійснила акцепт (прийняття) умов даного Договору (далі по тексту –
          <b>Замовник</b>), з другої сторони, які разом далі по тексту іменовані як <b>«Сторони»</b>, а окремо –
          <b>«Сторона»</b>, діючи добровільно та з власного волевиявлення, дійшли взаємної згоди укласти даний Договір,
          про нижченаведене:
        </p>

        <h3 class="document_sub-title">1. Предмет Договору.</h3>
        <p class="document_sub-list">
          <b>1.1.</b> На підставі даного Договору, в порядку та на визначених ним умовах, Виконавець приймає на себе
          зобов’язання надавати Замовнику послуги з повного доступу до системи «Dentabula» (далі по тексту – Система) в
          межах оплаченого періоду часу (далі по тексту – Послуги), а Замовник в свою чергу зобов’язується такі Послуги
          оплачувати.
        </p>
        <p class="document_sub-list">
          <b>1.2.</b> Виконавець надає Замовнику Послуги шляхом надання повного доступ до всіх елементів Системи, з
          можливістю завантаження, обробки, перегляду та виводу відповідних спеціалізованих комп’ютерних файлів, в межах
          наданого такою Системою функціоналу, виключно протягом певного обмеженого періоду часу.
        </p>
        <p class="document_sub-list">
          <b>1.3.</b> Умови даного Договору можуть бути змінені Виконавцем в односторонньому порядку, без погодження із
          Замовником, та без будь-якого спеціального повідомлення про це. Нова редакція умов Договору вступає в силу з
          моменту її розміщення на офіційному сайті Системи, якщо інше не передбачено новою редакцією Договору. Поточна
          та чинна редакція Договору завжди знаходиться на офіційному сайті Системи за адресою:
          <a href="https://www.dentabula.com">https://www.dentabula.com</a>, із зазначенням номеру та дати її редакції.
        </p>
        <p class="document_sub-list">
          <b>1.4.</b> Форма даного Договору відповідає вимогам приватного права, вказаного в п. 1.5. Договору.
        </p>
        <p class="document_sub-list">
          <b>1.5.</b> До змісту даного Договору застосовується приватне право національного законодавства України.
        </p>

        <h3 class="document_sub-title">2. Порядок та умови надання Послуг.</h3>
        <p class="document_sub-list">
          <b>2.1.</b> Послуги споживаються Замовником в процесі вчинення ним будь-яких дій з використання Системи.
          Виконавець зобов’язується надавати Послуги виключно з першого дня та по останній день відповідного оплаченого
          Замовником періоду часу. Надання Виконавцем Послуг розпочинається одразу після оплати Замовником їх вартості,
          згідно умов даного Договору, та припиняється одразу після закінчення оплаченого періоду часу.
          <br />
          Період часу, протягом якого Виконавець зобов’язується надавати Замовнику Послуги, а також вартість таких
          Послуг визначена на офіційному сайті Системи, за адресою:
          <a href="https://www.dentabula.com">https://www.dentabula.com</a>. Укладаючи даний Договір, Замовник
          підтверджує, що він ознайомлений із умовами, періодом та вартістю надання Послуг Виконавцем.
        </p>
        <p class="document_sub-list">
          <b>2.2.</b> Послуги надаються Виконавцем особисто. За необхідності, на розсуд Виконавця та за його бажанням,
          без попередньої згоди Замовника та не вступаючи у протиріччя з умовами даного Договору, Виконавець має право у
          будь-який момент покласти виконання Договору на іншу особу, залишаючись при цьому відповідальним в повному
          обсязі перед Замовником за порушення його умов.
        </p>
        <p class="document_sub-list">
          <b>2.3.</b> Послуги, які надаються за даним Договором, повинні відповідати наступним властивостям:
          <br />
          - можливість безперервного та безперебійного доступу Замовника до Системи у будь-який час будь-якої доби,
          виключно в межах та протягом оплаченого ним періоду, та за умови наявності у Замовника безперервного та
          безперебійного доступ до мережі Інтернет;
          <br />
          - можливість завантаження, обробки, перегляду та виводу Замовником відповідних спеціалізованих комп’ютерних
          файлів, які містять в собі зображення та 3D моделі стоматологічних складових (в тому числі, але не виключно:
          ортодонтичних, ортопедичних, хірургічних, терапевтичних, анатомічних матеріалів).
        </p>
        <p class="document_sub-list">
          <b>2.4.</b> Для можливості виконання зобов’язань за даним Договором, Замовник повинен самостійно
          зареєструватися в Системі на її офіційному сайті.
        </p>
        <p class="document_sub-list">
          <b>2.5.</b> При реєстрації Замовник самостійно вибирає собі унікальне ім’я (логін) облікового запису і пароль
          для доступу до нього. Виконавець має право заборонити використання певних логінів, а також встановлювати
          вимоги до логіну та паролю (довжина, допустимі символи, та ін.).
        </p>
        <p class="document_sub-list">
          <b>2.6.</b> Замовник самостійно несе відповідальність за безпеку (стійкість до вгадування) обраного ним
          пароля, а також самостійно забезпечує конфіденційність свого пароля. Замовник самостійно несе відповідальність
          за всі дії/бездіяльність (а також їх наслідки) у рамках або з використанням Системи під його обліковим
          записом, включаючи випадки добровільної передачі або недотримання конфіденційності даних для доступу до
          облікового запису Замовника третім особам на будь-яких умовах (у тому числі за договорами або угодами). При
          цьому всі дії в рамках або з використанням Системи під обліковим записом Замовника вважаються здійсненими
          самим Замовником, за винятком одержання Виконавцем від Замовника спрямованого електронного повідомлення про
          несанкціоноване використання Системи під обліковим записом Замовника та/або про будь-яке порушення (підозри
          про порушення) конфіденційності свого пароля.
        </p>
        <p class="document_sub-list">
          <b>2.7.</b> Замовник зобов'язаний негайно повідомити Виконавця про будь-який випадок несанкціонованого (не
          дозволеного Замовником) доступу до Системи за допомогою облікового запису Замовника та/або про будь-яке
          порушення (підозри про порушення) конфіденційності свого пароля. В цілях безпеки, Замовник зобов'язаний
          самостійно здійснювати безпечне завершення роботи під своїм обліковим записом (кнопка «Вихід») після
          закінчення кожної сесії роботи з Системою. Виконавець не несе відповідальності за можливу втрату або псування
          даних, а також інші наслідки будь-якого характеру, які можуть статися через порушення Замовником положень
          даного пункту Договору.
        </p>
        <p class="document_sub-list">
          <b>2.8.</b> Виконавець має право заблокувати та/або видалити обліковий запис Замовника, включаючи весь
          завантажений у Систему останнім контент без пояснення причин (у тому числі, але не виключно, в разі порушення
          Замовником умов даного Договору), в разі невикористання Системи протягом 50 (п'ятдесяти) календарних днів
          підряд. Під невикористанням Системи в даному Договорі розуміється відсутність даних в архівній системі
          Виконавця про авторизацію Замовника в Системі.
        </p>
        <p class="document_sub-list">
          <b>2.9.</b> Замовнику не дозволяється використовувати Систему будь-яким способом, якщо таке використання
          суперечить чи призводить до порушення національного законодавства (права) України та/або міжнародних
          договорів, в яких бере участь Україна, та приписів національного колізійного законодавства України.
        </p>
        <p class="document_sub-list">
          <b>2.10.</b> Після оплати Послуг, Замовнику надається тимчасовий повний доступ до Системи, при цьому останній
          не вправі вчиняти дії, які можуть спричинити: а) порушення функціонування обладнання та мережі Виконавця; б)
          порушення роботи Системи або обмеження можливостей інших користувачів у використанні Системи; в)
          несанкціонований доступ до Системи, а також до інформаційно-обчислювальних і мережних ресурсів Виконавця; г)
          заподіяння або загрозу заподіяння шкоди третім особам.
        </p>
        <p class="document_sub-list">
          <b>2.11.</b> Замовнику забороняється модифікувати Систему, її дизайн і зовнішній вигляд.
        </p>
        <p class="document_sub-list">
          <b>2.12.</b> Замовник самостійно забезпечує підключення свого робочого місця до мережі Інтернет, а також
          самостійно забезпечує комплектацію свого робочого місця у відповідності з вимогами, розміщеними на офіційному
          сайті Системи.
        </p>
        <p class="document_sub-list">
          <b>2.13.</b> Замовник гарантує, що він має всі необхідні права на всі дані, комп'ютерні програми або сервіси,
          які використовуються ним у зв'язку з використанням Системи, і що такі дії не порушують будь-яких прав третіх
          осіб.
        </p>
        <p class="document_sub-list">
          <b>2.14.</b> Замовник не має права відтворювати Систему, а також копіювати, продавати та перепродавати її
          (включаючи контент, доступний Замовнику в рамках Системи), або доступ до неї, крім тих випадків, коли Замовник
          отримав такий дозвіл від Виконавця.
        </p>

        <h3 class="document_sub-title">3. Вартість Послуг та порядок оплати.</h3>
        <p class="document_sub-list">
          <b>3.1.</b> Вартість Послуг за даним Договором вказана на офіційному сайті Системи, за адресою:
          <a href="https://www.dentabula.com">https://www.dentabula.com</a>. Укладаючи даний Договір Замовник
          підтверджує, що він ознайомлений із вартістю надання Послуг Виконавцем.
        </p>
        <p class="document_sub-list">
          <b>3.2.</b> Виконавець має право в будь-який час в односторонньому порядку та без попереднього погодження із
          Замовником змінювати вартість Послуг. Змінена Виконавцем вартість послуг набуває чинності на 2-й (другий)
          календарний день з моменту її опублікування на офіційному сайті Системи, якщо інший строк набрання нею
          чинності не вказаний Виконавцем на офіційному сайті Системи. У випадку зміни вартості Послуг, вже здійснена
          раніше Замовником оплата до моменту набрання чинності нової вартості – не перераховується.
        </p>
        <p class="document_sub-list">
          <b>3.3.</b> Оплата Послуг здійснюються Замовником в безготівковій формі, шляхом перерахування відповідної суми
          грошових коштів на розрахунковий рахунок Виконавця, за допомогою використання міжнародних банківських
          платіжних карт через відповідну платіжну систему (інтернет-еквайринг), обраною в односторонньому порядку та на
          розсуд Виконавця.
        </p>
        <p class="document_sub-list">
          <b>3.4.</b> Укладаючи даний Договір Замовник підтверджує, що він обізнаний відносно наступного: Виконавець не
          вимагає від Замовника надання будь-яких відомостей про його платіжні інструменти, банківські рахунки, платіжні
          картки, а також будь-яких інших відомостей, які необхідні для оплати вартості Послуг за даним Договором.
          Виконавець в жодному разі не несе відповідальності за збереження вказаних вище та подібних відомостей, що
          дають змогу забезпечити проведення Замовником оплати Послуг відповідним оператором платіжних систем, навіть
          якщо такі відомості були отримані Виконавцем випадково або через навмисні або помилкові дії Замовника.
        </p>
        <p class="document_sub-list">
          <b>3.5.</b> Інформація щодо включення у розрахунок вартості Послуг за даним Договором податків – зазначається
          на офіційному сайті Системи.
        </p>
        <p class="document_sub-list">
          <b>3.6.</b> Одразу після здійснення оплати Послуг, Замовник отримує підтвердження про це у формі електронної
          квитанції, яку за бажанням останнього може бути надіслано на його адресу електронної пошти або завантажено до
          відповідного пристрою для зберігання даних.
        </p>
        <p class="document_sub-list"><b>3.7.</b> Укладаючи даний Договір Сторони визначили наступне:</p>
        <p class="document_sub-list">
          <b>3.7.1.</b> Вартість Послуг за даним Договором визначається, в тому числі і у вигляді грошового еквіваленту
          в іноземній валюті (обраній в односторонньому порядку Виконавцем та на його розсуд) на офіційному сайті
          «Dentabula» в мережі Інтернет за адресою: <a href="https://www.dentabula.com">https://www.dentabula.com</a>.
          Розмір вартості Послуг, виражений в національній валюті України, кожного разу залежить від щоденного поточного
          валютного курсу продажу відповідної іноземної валюти за гривні, згідно даних Національного банку України. При
          оплаті Замовником вартості Послуг, сума такого платежу у національній валюті України автоматично визначається
          за відповідним поточним валютним курсом продажу відповідної іноземної валюти за гривні станом на відповідний
          день здійснення Замовником такого платежу. Здійснюючи оплату вартості Послуг, Замовник погоджується з тим, що
          кінцева сума платежу може змінюватися відповідно до валютно-обмінної політики Національного банку України.
        </p>

        <h3 class="document_sub-title">4. Права та обов'язки Сторін.</h3>
        <p class="document_sub-list">
          <b>4.1.</b> Виконавець зобов’язаний:
          <br />
          - своєчасно, належним чином та в повному обсязі виконувати зобов’язання за даним Договором;
          <br />
          - надавати Замовнику Послуги виключно протягом оплаченого останнім обмеженого періоду часу;
        </p>
        <p class="document_sub-list">
          <b>4.2.</b> Замовник зобов’язаний:
          <br />
          - своєчасно, належним чином та в повному обсязі виконувати умови даного Договору;
          <br />
          - оплачувати вартість Послуг відповідно до умов Договору;
          <br />
          - для коректної роботи Системи забезпечити себе та своє робоче місце відповідним апаратно-програмним
          середовищем, вимоги до якого наведені на офіційному сайті Системи;
          <br />
          - самостійно, та за власні кошти і власними силами забезпечувати конфіденційність свого логіна і пароля,
          необхідних для доступу до його облікового запису в Системі;
          <br />
          - не передавати Послуги третім особам.
        </p>
        <p class="document_sub-list">
          <b>4.3.</b> Виконавець має право:
          <br />
          - змінити склад, порядок, умови та вартість надання Послуг шляхом розміщення відповідної інформації на
          офіційному сайті Системи;
          <br />
          - припинити надавати Послуги автоматично після закінчення оплаченого Замовником періоду;
          <br />
          - призупинити або зупинити надання Послуг Замовнику без повернення грошових коштів і вимагати письмових
          пояснень від Замовника у випадках: якщо Виконавець вважає що будь-які дії, вчинені Замовником через Послуги,
          надані Замовнику згідно даного Договору, завдають або можуть завдати шкоди Виконавцю, та/або іншим Замовникам;
          в разі пошкодження чи намагання пошкодити засоби захисту Системи, а також включення програмного забезпечення
          до складу власних розробок Замовника; при публікації та передачі Замовником через мережу інтернет будь-якої
          інформації, яка паплюжить Виконавця чи скоєння дій, що перешкоджають нормальній роботі Виконавця;
          <br />
          - заблокувати доступ до Системи з певної IP-адреси у випадках: перевищення кількості запитів з IP-адреси за
          певний проміжок часу; надсилання запитів, які не передбачені клієнтською програмою Системи; дій з Системою, що
          можуть призвести до наслідків, передбачених розділом XVI Кримінального кодексу України «Злочини у сфері
          використання електронно-обчислювальних машин (комп'ютерів), систем та комп'ютерних мереж і мереж
          електрозв'язку».
        </p>
        <p class="document_sub-list">
          <b>4.4.</b> Замовник має право:
          <br />
          - вимагати від Виконавця надання Послуг відповідно до умов даного Договору;
          <br />
          - у разі виникнення зауважень та/або претензій щодо якості Послуг повідомляти про них Виконавця письмово.
          Зауваження та/або претензії приймаються в письмовому вигляді, викладені українською, англійською або
          російською мовами, шляхом їх направлення на наступну адресу електронної пошти Виконавця:
          dentabula.AP@gmail.com. Зауваження та/або претензії розглядається Виконавцем протягом 30 календарних днів з
          моменту їх отримання. В разі, коли зауваження та/або претензія є надуманими та/або необґрунтованими,
          Виконавець має право залишити їх без розгляду та/або без відповіді;
          <br />
          - відмовитися від даного Договору, та вимагати відшкодування збитків, якщо Виконавець своєчасно не приступив
          до виконання своїх зобов’язань за Договором;
          <br />
          - розірвати Договір, якщо стане очевидним, що Послуги не будуть надані Виконавцем у відповідності до умов
          даного Договору;
          <br />
          - в разі виявлення недоліків у Послугах, Замовник має право на свій вибір вимагати: безоплатного усунення
          недоліків у наданій Послузі у розумний строк; відповідного зменшення ціни наданої Послуги.
        </p>

        <h3 class="document_sub-title">
          5. Умови використання Системи.
          <br />
          Авторські права.
        </h3>
        <p class="document_sub-list">
          <b>5.1.</b> Виконавець дозволяє Замовнику обробляти, переглядати і завантажувати та виводити матеріали Системи
          тільки для особистого використання, за умови збереження Замовником всієї інформації про авторське право та
          інших відомостей про право власності, що містяться у вихідних матеріалах і будь-яких їхніх копіях.
          Забороняється змінювати матеріали Системи, а також поширювати їх в будь-якому вигляді. Будь-яке використання
          матеріалів Системи на інших сайтах або в комп'ютерних мережах забороняється.
        </p>
        <p class="document_sub-list">
          <b>5.2.</b> Система є результатом інтелектуальної діяльності Виконавця та об'єктом його авторських прав, які
          регулюються і захищені національним законодавством (правом) України з урахуванням міжнародних договорів, в
          яких бере участь Україна, та приписів національного колізійного законодавства України.
        </p>
        <p class="document_sub-list">
          <b>5.3.</b> Алгоритми роботи Системи та її вихідні коди (у тому числі їх частини) є комерційною таємницею
          Виконавця. Будь-яке їх використання або використання Системи в порушення умов цього Договору розглядається як
          порушення прав Виконавця і є достатньою підставою для позбавлення Замовника наданих за даним Договором прав,
          та його подальшого притягнення до відповідної правової відповідальності.
        </p>
        <p class="document_sub-list">
          <b>5.4.</b> Відповідальність за порушення авторських прав настає у відповідності до національного
          законодавства (права) України з урахуванням міжнародних договорів, в яких бере участь Україна, та приписів
          національного колізійного законодавства України.
        </p>
        <p class="document_sub-list">
          <b>5.5.</b> Система і авторство на неї, ідеї, методи роботи, документація та інша інформація, яка міститься
          та/або може бути розміщена в Системі, є інтелектуальною власністю та/або комерційною таємницею Виконавця, яка
          охороняється національним законодавством (правом) України з урахуванням міжнародних договорів, в яких бере
          участь Україна, та приписів національного колізійного законодавства України. Виконавець має і зберігає за
          собою всі права, пов'язані із Системою, включаючи без всяких обмежень будь-які виправлення помилок,
          поліпшення, оновлення або інші операції по модифікації Системи, а також всі авторські права, пов'язані з
          інтелектуальною власністю на Систему. Факти використання Замовником Системи в жодному разі не дають останньому
          ніяких прав інтелектуальної власності на неї, і Замовник не отримує інших прав на Систему, крім спеціально
          обумовлених у цьому Договорі. Даний Договір лише дає Замовнику право використання Системи виключно в рамках
          даного Договору.
        </p>
        <p class="document_sub-list">
          <b>5.6.</b> Замовник погоджується із тим, що вихідний код Системи є власністю Виконавця і являє собою
          комерційну таємницю. Замовник погоджується, що він не буде змінювати, декомпілювати, аналізувати, та/або
          намагатися розкрити вихідний код Системи будь-яким іншим шляхом.
        </p>
        <p class="document_sub-list">
          <b>5.7.</b> Замовник погоджується, що всі авторські права, і всі інші пов'язані з цим права власності, на
          офіційному сайті Системи (в тому числі, але не виключно: файлова база даних (бібліотека) зображень та 3D
          моделей ортодонтичних, ортопедичних, хірургічних, терапевтичних, анатомічних стоматологічних складових та
          інших пов’язаних із цим матеріалів, а також Програма, за допомогою якої здійснюється використання Системи для
          завантаження, обробки, перегляду та виводу відповідних спеціалізованих комп’ютерних файлів) належать
          Виконавцю. Замовник погоджуєтеся та зобов’язується не копіювати, не перевидавати, не кардувати, не
          завантажувати, не передавати, не змінювати, не здавати в оренду, не продавати, не поширювати, не ліцензувати,
          не перепроектувати Систему та/або окремі її частини, або створювати похідні від неї та засновані на ній
          об’єкти, сайти, системи, програми, бази даних, тощо.
        </p>

        <h3 class="document_sub-title">6. Відмова від гарантій.</h3>
        <p class="document_sub-list">
          <b>6.1.</b> Матеріали Системи і Послуги за даним Договором надаються Виконавцем Замовнику «як є» і «як
          доступно», та без будь-яких гарантій. Виконавець не гарантує точності і повноти матеріалів, програми і Послуг,
          що надаються Системою та Виконавцем. Виконавець в будь-який час без повідомлення Замовника може вносити зміни
          в матеріали і Послуги, що надаються Системою та Виконавцем. У разі старіння матеріалів Системи Виконавець не
          зобов'язаний оновлювати їх. Виконавець ні за яких обставин не несе відповідальності за будь-які збитки що
          виникли внаслідок використання, неможливості використання або результатів використання Системи.
        </p>
        <p class="document_sub-list">
          <b>6.2.</b> Виконавець не дає жодних обіцянок та/або гарантій (явних та/або неявних). Замовник приймає на себе
          увесь ризик та відповідальність за використання Системи для отримання будь-яких результатів.
        </p>
        <p class="document_sub-list">
          <b>6.3.</b> Виконавець не дає жодних гарантій на те, що в Системі не будуть наявні помилки, не буде ніяких
          пауз та/або збоїв у її роботі, та що вона буде сумісною та/або підтримуватися будь-якою апаратно-програмною
          та/або операційною системою.
        </p>
        <p class="document_sub-list">
          <b>6.4.</b> Виконавець знімає з себе будь-яку відповідальність за втрату будь-яких даних під час використання
          Системи та під час процесу обміну ними, а також відповідальність за не передачу повної та достовірної
          інформації.
        </p>
        <p class="document_sub-list">
          <b>6.5.</b> Використання Системи здійснюється Замовником на його власний ризик і за його згодою. Замовник несе
          персональну і повну відповідальність за будь-які пошкодження його пристроїв, обладнання та/або програм, втрату
          даних або іншу заподіяну шкоду внаслідок використання Системи. Виконавець не несе відповідальності за
          зараження комп'ютерними вірусами або подібними програмними кодами, які можуть бути завантажені Замовником з
          офіційного сайту Системи.
        </p>

        <h3 class="document_sub-title">7. Відповідальність Сторін.</h3>
        <p class="document_sub-list">
          <b>7.1.</b> Сторони даного Договору несуть відповідальність за предметом і умовами Договору відповідно до
          національного законодавства (права) України з урахуванням міжнародних договорів, в яких бере участь Україна,
          та приписів національного колізійного законодавства України.
        </p>
        <p class="document_sub-list">
          <b>7.2.</b> Замовник повністю відповідальний за збереження свого логіна і пароля і за збитки, які можуть
          виникнути через несанкціоноване його використання. За фактом крадіжки логіна і пароля з вини третіх осіб
          клієнт має право направити на адресу Виконавця заяву про зміну логіна і пароля, з обов'язковим додатком до
          заяви відповідної квитанції, яка підтверджує оплату Послуг. Виконавець не несе відповідальність за дії третіх
          осіб, які спричинили крадіжку, а для відшкодування грошових коштів, витрачених на вкрадений час Змовник може
          звернутися у відповідні слідчі і правоохоронні органи за місцем свого перебування.
        </p>

        <h3 class="document_sub-title">8. Застереження про форс-мажорні обставини.</h3>
        <p class="document_sub-list">
          <b>8.1.</b> Сторони даного Договору звільняється від будь-якої відповідальності за повне чи часткове порушення
          умов даного Договору, якщо доведуть, що таке порушення сталося внаслідок дії форс-мажорних обставин,
          визначених у цьому Договорі, за умови, що їх настання було засвідчено у визначеному цим Договором порядку.
        </p>
        <p class="document_sub-list">
          <b>8.2.</b> Під форс-мажорними обставинами у цьому Договорі розуміються випадок та/або непереборна сила, які
          визначені у пп. 8.3. та 8.4. цього Договору, як підстава для звільнення від відповідальності за порушення умов
          Договору.
        </p>
        <p class="document_sub-list">
          <b>8.3.</b> Під непереборною силою у цьому Договорі розуміються будь-які надзвичайні події зовнішнього щодо
          Сторін характеру, які виникають без вини Сторін, поза їх волею або всупереч волі чи бажанню Сторін, і які не
          можна за умови вжиття звичайних для цього заходів передбачити та не можна при всій турботливості та обачності
          відвернути (уникнути), включаючи (але не обмежуючись): стихійні явища природного характеру (землетруси,
          повені, урагани, руйнування в результаті блискавки тощо), лиха біологічного, техногенного та антропогенного
          походження (вибухи, пожежі, вихід з ладу машин й обладнання, масові епідемії, епізоотії, епіфітотії тощо),
          обставини суспільного життя (війна, воєнні дії, блокади, громадські хвилювання, прояви тероризму, масові
          страйки та локаути, бойкоти тощо), а також видання заборонних або обмежуючих нормативних актів органів
          державної влади чи місцевого самоврядування, інші законні або незаконні заборонні чи обмежуючі заходи названих
          органів, які унеможливлюють виконання Сторонами цього Договору або тимчасово перешкоджають такому виконанню.
        </p>
        <p class="document_sub-list">
          <b>8.4.</b> Під випадком у цьому Договорі розуміються будь-які обставини, які не вважаються непереборною силою
          за цим Договором і які безпосередньо не обумовлені діями Сторін та не пов'язані із ними причинним зв'язком,
          які виникають без вини Сторін, поза їх волею або всупереч волі чи бажанню Сторін, і які не можна за умови
          вжиття звичайних для цього заходів передбачити та не можна при всій турботливості та обачності відвернути
          (уникнути).
        </p>
        <p class="document_sub-list">
          <b>8.5.</b> Не вважаються випадком недодержання своїх обов'язків контрагентом тієї Сторони, що порушила цей
          Договір, відсутність у Сторони, що порушила Договір, необхідних коштів.
        </p>
        <p class="document_sub-list">
          <b>8.6.</b> Настання непереборної сили має бути засвідчено компетентним органом, що визначений чинним
          законодавством.
        </p>
        <p class="document_sub-list">
          <b>8.7.</b> Сторона, що має намір послатися на форс-мажорні обставини, зобов'язана невідкладно із урахуванням
          можливостей технічних засобів миттєвого зв'язку та характеру існуючих перешкод повідомити іншу Сторону про
          наявність форс-мажорних обставин та їх вплив на виконання цього Договору.
        </p>
        <p class="document_sub-list">
          <b>8.8.</b> Якщо форс-мажорні обставини та їх наслідки тимчасово перешкоджають виконанню цього Договору, то
          виконання цього Договору зупиняється на строк, протягом якого воно є неможливим.
        </p>
        <p class="document_sub-list">
          <b>8.9.</b> Якщо у зв'язку із форс-мажорними обставинами та їх наслідками, за які жодна із Сторін не
          відповідає, виконання цього Договору є остаточно неможливим, то цей Договір вважається припиненим з моменту
          виникнення неможливості виконання цього Договору, однак Сторони не звільняються від обов'язку, визначеного у
          п. 8.7. цього Договору.
        </p>
        <p class="document_sub-list">
          <b>8.10.</b> Якщо у зв'язку із форс-мажорними обставинами та їх наслідками виконання цього Договору є
          тимчасово неможливим і така неможливість триває протягом 90 календарних днів і не виявляє ознак припинення, то
          цей Договір може бути розірваний в односторонньому порядку будь-якою Стороною шляхом направлення за допомогою
          електронного поштового зв'язку письмової заяви іншій Стороні.
        </p>

        <h3 class="document_sub-title">
          9. Керуюче законодавство (право).
          <br />
          Договірна підсудність.
        </h3>
        <p class="document_sub-list">
          <b>9.1.</b> Сторони погодили, що даний Договір в цілому та пов’язані із ним правові відносини між Виконавцем
          та Замовником регулюються, тлумачаться та виконуються у відповідності до національного законодавства (права)
          України. В разі, коли Замовником є громадянин України, який проживає за її межами, або іноземець, або особа
          без громадянства, відносини між Сторонами регулюються відповідно до національного законодавства (права)
          України з урахуванням міжнародних договорів, в яких бере участь Україна, та приписів національного колізійного
          законодавства України.
        </p>
        <p class="document_sub-list">
          <b>9.2.</b> Усі спори, розбіжності чи вимоги, які виникають із цього Договору або у зв'язку з ним, зокрема,
          щодо його тлумачення, виконання, порушення, припинення чи недійсності, підлягають вирішенню відповідно до
          національного законодавства (права) України з урахуванням міжнародних договорів, в яких бере участь Україна.
        </p>
        <p class="document_sub-list">
          <b>9.3.</b> Всі суперечки та/або спори, які можуть виникнути на підставі даного Договору або в зв'язку з ним,
          повинні бути передані на вирішення відповідному місцевому суду України загальної юрисдикції за
          місцезнаходженням Виконавця.
        </p>

        <h3 class="document_sub-title">10. Строк дії Договору та порядок його розірвання.</h3>
        <p class="document_sub-list">
          <b>10.1.</b> Договір набирає чинності з моменту акцепту оферти Замовником, яка здійснюється шляхом оплати
          Послуг у визначений його умовами спосіб і порядок, та діє до моменту його розірвання за домовленістю Сторін
          або за ініціативою однієї Сторони у порядку, визначеному Договором.
        </p>
        <p class="document_sub-list">
          <b>10.2.</b> Договір може бути розірваний в наступних випадках:
          <br />
          - за згодою Сторін в будь-який час;
          <br />
          - за ініціативою будь-якої Сторони в разі порушення умов Договору іншою Стороною з обов’язковим письмовим
          повідомленням іншої Сторони, відправленим на електрону пошту;
          <br />
          - за ініціативою будь-якої Сторони з письмовим повідомленням іншої Сторони за 15 календарних днів до
          розірвання Договору, відправленим на електрону пошту.
          <br />
        </p>
        <p class="document_sub-list">
          <b>10.3.</b> Припинення або розірвання Договору не звільняє Сторін від виконання обов’язків, що виникли до, чи
          в момент припинення або розірвання Договору.
        </p>

        <h3 class="document_sub-title">11. Інше.</h3>
        <p class="document_sub-list">
          <b>11.1.</b> Будь-які повідомлення, листи, зауваження та/або претензії по даному Договору можуть направлятися
          Замовником Виконавцю на адресу електронної пошти з адреси, вказаної Замовником при реєстрації, Виконавцем
          Замовнику на адресу, вказану Замовником при реєстрації з адреси, вказаної в розділі 12 «Реквізити Виконавця».
        </p>
        <p class="document_sub-list">
          <b>11.2.</b> Внесення змін у даний Договір здійснюється згідно із пунктом 1.3. Договору.
        </p>
        <p class="document_sub-list">
          <b>11.3.</b> Можлива недійсність однієї або більше умов Договору не тягне за собою недійсності всього Договору
          або окремих його частин, які залишаються чинними.
        </p>
        <p class="document_sub-list">
          <b>11.4.</b> У випадках, не врегульованих даним Договором, Сторони керуються нормами національного
          законодавства (права) України з урахуванням міжнародних договорів, в яких бере участь Україна, та приписів
          національного колізійного законодавства України.
        </p>
        <p class="document_sub-list">
          <b>11.5.</b> Кожна з Сторін підтверджує, що: вона має усі повноваження укласти цей Договір; не існує будь-яких
          обмежень на укладення Договору; умови даного Договору зрозумілі і відповідають реальній домовленості Сторін;
          укладення Договору відповідає інтересам Сторін.
        </p>

        <h3 class="document_sub-title">12. Реквізити Виконавця.</h3>
        <p class="document_sub-p">
          Фізична особа-підприємець Погарський Антон Юрійович
        </p>
        <p class="document_sub-p">
          Дата та номер запису в Єдиному державному реєстрі юридичних осіб, фізичних осіб-підприємців та громадських
          формувань: 28.03.2018 року, № 21030000000096301;
        </p>
        <p class="document_sub-p">
          адреса (місце фактичного проживання): 69600, Україна, Запорізька область, місто Запоріжжя, Комунарський район,
          вул. Реконструктивна, будинок 3;
        </p>
        <p class="document_sub-p">
          реєстраційний номер облікової картки платника податків: 2987814459;
        </p>
        <p class="document_sub-p">
          адреса електронної пошти: dentabula.AP@gmail.com;
        </p>
        <p class="document_sub-p">
          режим роботи: з понеділка по п’ятницю кожного тижня, з 10 год. 00 хв. до 17 год. 00 хв.;
        </p>
        <p class="document_sub-p">
          тел.: + 38 097 977 39 98.
        </p>
      </div>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
// import translate from './translate.js'

export default {
  name: "Contract",
  components: { mainMenu, mainFooter },
  data() {
    return {
      // translate: translate,
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.currentLang;
    },
  },
};
</script>

<style lang="sass">
#contract
	.wrapper
		display: flex
		max-width: 1024px
		margin: 0 auto
		padding: 0 0.5rem
		min-height: calc(100vh - 280px)
		.document
			padding: 1rem 0 3rem
			.document_sub-heading
				text-align: center
				margin-top: 1rem
				margin-bottom: 1rem
				font-size: 1rem
				font-weight: bold
			.document_sub-title
				text-align: center
				margin-top: 1rem
				margin-bottom: 1rem
				font-weight: bold
			.document_sub-table
				width: 100%
				& tr:first-child
					& td
						padding-bottom: 0.5rem
			.document_sub-p
				text-indent: 2rem
				margin-bottom: 1rem
				text-align: justify
			.document_sub-list
				text-indent: 2rem
				text-align: justify
</style>
